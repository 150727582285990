import React, { useState } from "react";
import { Link } from "gatsby";

import "./login-tabs.bootstrap.scss";

// React Bootstrap
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Form } from "react-bootstrap";

// Components
import SEO from "../components/seo/seo";
import SecondaryLanding from "../layouts/secondary-landing";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import InfoCircleTooltip from "../components/custom-widgets/info-circle-tooltip";
import PersonalizationMessageBar from "../components/notifications/personalization-message-bar";
import Icon from "../components/custom-widgets/icon";
import PersonalizationCards from "../components/card/personalization-cards";

const Login = () => {
  const seoData = {
    title: "WaFd Bank Login | Personal, Business, and Commercial",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "WaFd Bank Login | Personal, Business, and Commercial"
      },
      {
        name: "description",
        content:
          "Login to your WaFd Bank account (formally known as Washington Federal). Access your personal banking, business banking, or commercial accounts today."
      },
      {
        property: "og:title",
        content: "WaFd Bank Login | Personal, Business, and Commercial"
      },
      {
        property: "og:description",
        content:
          "Login to your WaFd Bank account (formally known as Washington Federal). Access your personal banking, business banking, or commercial accounts today."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/login"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      url: "/login",
      title: "Login"
    }
  ];

  const RightArrow = () => {
    return <Icon name="arrow-right" lib="far" class="ml-1" />;
  };

  const [selectedPerValue, setSelectedPerValue] = useState({
    name: "Online Banking",
    id: "login-page-personal-online-banking-sign-in",
    href: "https://online.wafdbank.com/index.html"
  });
  const handleSelectPer = (e) => {
    setSelectedPerValue(personalLoginValues.find((n) => n.name === e));
  };

  const personalLoginValues = [
    {
      name: "Online Banking",
      id: "login-page-personal-online-banking-sign-in",
      href: "https://online.wafdbank.com/index.html"
    },
    {
      name: "Credit Card",
      id: "login-page-personal-credit-card-sign-in",
      href: "https://www.ezcardinfo.com/?WAFD=/"
    }
  ];

  const [selectedBizValue, setSelectedBizValue] = useState("");
  const handleSelectBiz = (e) => {
    setSelectedBizValue(businessLoginValues.find((n) => n.name === e));
  };

  const businessLoginValues = [
    {
      name: "",
      id: "login-page-business-default-sign-in",
      href: "/"
    },
    {
      name: "Treasury Express",
      id: "login-page-treasury-express-sign-in",
      href: "https://commercial.washingtonfederal.com/smallbusiness/auth"
    },
    {
      name: "Treasury Prime",
      id: "login-page-treasury-prime-sign-in",
      href: "https://commercial.washingtonfederal.com"
    },
    {
      name: "WaFd Client Connect",
      id: "login-page-client-connect-sign-in",
      href: "https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5"
    },
    {
      name: "Credit Cards",
      id: "login-page-business-credit-card-sign-in",
      href: "https://www.ezcardinfo.com/?WAFD=/"
    },
    {
      name: "Credit Card Administration",
      id: "login-page-business-credit-card-admin-sign-in",
      href: "https://www.ezbusinesscardmanagement.com/bid/88003S"
    }
  ];

  const [key, setKey] = useState("personal");

  const personalizationCardData = {
    defaultCardsArray: [1]
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <PersonalizationMessageBar />
      <Breadcrumb data={breadcrumbData} />

      <section className="container">
        <h1 className="h2-font-size">WaFd Bank Online Account Sign In</h1>
        <div className="row justify-content-between">
          <div className="col-lg-7 col-xl-6" id="personal-business-sign-in-tabs">
            <Tabs
              activeKey={key}
              onSelect={(k) => setKey(k)}
              aria-expanded="true"
              className="d-flex justify-content-between border-0 mb-3 sticky-top"
            >
              <Tab id="personal-sign-in" eventKey="personal" title="Personal Sign In">
                <div className="w-md-50">
                  <p id="first-time-signup-personal-tab" className="my-1">
                    First-time user?{" "}
                    <a id="personal-enroll-link" href="https://online.wafdbank.com/link/register?application=OLB">
                      Enroll Now
                    </a>
                  </p>
                  <Form.Group id="personal-banking-login-dropdrown" className="dropdown">
                    <Form.Label>
                      Select your account{" "}
                      <InfoCircleTooltip
                        id={"select-account-tooltip"}
                        trigger={["hover", "focus"]}
                        content={
                          "Sign in for personal accounts like checking, savings, personal credit card, or personal accounts being used for small business."
                        }
                        icon={{
                          title: "",
                          class: "text-gray-60",
                          name: "info-circle",
                          lib: "far"
                        }}
                      />
                    </Form.Label>
                    <Form.Control
                      id="login-page-select-account-type"
                      className="border-radius-6 select-input-text custom-select-chevron"
                      as="select"
                      defaultValue={""}
                      value={selectedPerValue.name}
                      onChange={(e) => handleSelectPer(e.target.value)}
                    >
                      {personalLoginValues.map((value, index) => (
                        <option key={index} value={value.name} id={value.id} className="dropdown-content">
                          {value.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <a
                    className={`btn btn-block mb-3 ${!selectedPerValue.name ? "btn-disabled" : "btn-success"}`}
                    href={selectedPerValue?.href}
                    id={`${selectedPerValue?.id}-button`}
                  >
                    Sign In
                  </a>
                </div>
                <p id={`personal-forgot-username-password`}>
                  Forgot{" "}
                  <a
                    id="login-page-personal-forgot-username"
                    className="text-underline"
                    href="https://online.wafdbank.com/link/forgotusername?application=olb"
                  >
                    Username
                  </a>{" "}
                  or{" "}
                  <a
                    id="login-page-personal-forgot-password"
                    className="text-underline"
                    href="https://online.wafdbank.com/link/forgotpassword?application=olb"
                  >
                    Password
                  </a>
                  ?
                </p>
                <h5 className="font-weight-bold">Learn more about:</h5>
                <ul className="blue-bullets">
                  <li>
                    <Link id="personal-banking-page-link" className="text-decoration-none" to="/personal-banking">
                      WaFd Personal account options
                      <RightArrow />
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="credit-cards-page-link"
                      className="text-decoration-none"
                      to="/personal-banking/credit-cards"
                    >
                      WaFd Credit Cards
                      <RightArrow />
                    </Link>
                  </li>
                </ul>
              </Tab>

              <Tab id="business-sign-in" eventKey="business" title="Business Sign In">
                <div className="w-md-50">
                  <Form.Group id="business-commercial-sign-in" className="dropdown">
                    <Form.Label>Select your account</Form.Label>
                    <Form.Control
                      id="login-page-select-business-account"
                      className="border-radius-6 select-input-text custom-select-chevron"
                      as="select"
                      defaultValue={""}
                      value={selectedBizValue.name}
                      onChange={(e) => handleSelectBiz(e.target.value)}
                    >
                      {businessLoginValues.map((value, index) => (
                        <option key={index} value={value.name} id={value.id} className="dropdown-content">
                          {value.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <a
                    className={`btn btn-block mb-3 ${!selectedBizValue.name ? "btn-disabled" : "btn-success"}`}
                    href={selectedBizValue?.href}
                    id={`${selectedBizValue?.id}-button`}
                  >
                    Sign In
                  </a>
                </div>
                <p id="business-first-time-enroll">
                  First-time user?{" "}
                  <a id="login-page-business-enroll-link" href="https://business.washingtonfederal.com/enrollment/">
                    Enroll Now
                  </a>
                </p>
                <h5 className="font-weight-bold">Learn more about:</h5>
                <ul className="blue-bullets">
                  <li>
                    <Link
                      id="treasury-management-page-link"
                      to="/commercial-banking/treasury-management-services"
                      className="text-decoration-none"
                    >
                      Treasury Management account options
                      <RightArrow />
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="small-business-credit-card-page-link"
                      to="/business-banking/small-business-credit-card"
                      className="text-decoration-none"
                    >
                      Small-Business Credit Cards
                      <RightArrow />
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="commercial-credit-card-page-link"
                      to="/commercial-banking/commercial-credit-card"
                      className="text-decoration-none"
                    >
                      Commercial Credit Cards
                      <RightArrow />
                    </Link>
                  </li>
                </ul>
              </Tab>
            </Tabs>
          </div>

          {/* Credit Card Personalization Tile */}
          <div className="col-md-6 col-lg-4">
            <PersonalizationCards {...personalizationCardData} />
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default Login;
